import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { ReactComponent as Logo } from "../assets/svg/antje-muetzel-logo-min.svg";
import { ReactComponent as Vt } from "../assets/svg/verhaltenstherapie.svg";
import { ReactComponent as Coaching } from "../assets/svg/coaching.svg";
import { ReactComponent as Bs } from "../assets/svg/behandlungsspektrum.svg";
import { ReactComponent as Anjte } from "../assets/svg/antje-muetzel-icon.svg";
import { ReactComponent as Abrechnung } from "../assets/svg/therapie-abrechnung-icon.svg";
// import { ReactComponent as Faq } from '../assets/svg/therapie-faq-icon.svg'

import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, PhoneIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";

const therapyFields = [
  {
    name: "Kognitive Verhaltenstherapie",
    description:
      'Je nach Symptomausprägung liegt der Schwerpunkt auf der positiven Veränderung von Verhaltensweisen, Denkmustern, Gefühlen und körperlichem Empfinden nach dem Prinip der "Hilfe zur Selbsthilfe".',
    href: "/kognitive-verhaltenstherapie",
    icon: Vt,
  },
  {
    name: "Coaching",
    description:
      "Unterstützung in lebensrelevanten Fragestellungen und Phasen der Veränderung.",
    href: "/coaching",
    icon: Coaching,
  },
  {
    name: "Paartherapie",
    description:
      "Depressionen, Burnout, Ängste, Trauma, Belastungen in Partnerschaft u. Sexualität, Schlafstörungen & weitere.",
    href: "/paartherapie",
    icon: Bs,
  },
];
const callsToAction = [
  {
    name: "Sprechstunde vereinbaren",
    href: "tel:+4915156911373",
    icon: PhoneIcon,
  },
];
const resources = [
  {
    name: "Über mich",
    description: "Erfahren Sie mehr über mich und meine Vita.",
    href: "/antje-muetzel",
    icon: Anjte,
  },
  {
    name: "Kosten",
    description: "Abrechnung und Kostenübernahme einer Psychotherapie.",
    href: "/kosten",
    icon: Abrechnung,
  },
];
const shortInfos = [
  // { id: 1, name: 'COVID 19: Sitzungen vor Ort sowie per Video-Konferenz über zertifizierte Videoanbieter mit Ende-zu-Ende-Verschlüsselung möglich' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header({ siteTitle }) {
  return (
    <>
      <Popover className="relative bg-white">
        {({ open }) => (
          <>
            <div className="px-4 mx-auto max-w-7xl sm:px-6">
              <div className="flex items-center justify-between py-6 border-b-2 border-gray-100 md:justify-start md:space-x-10">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <Link to="/">
                    <span className="sr-only">
                      Verhaltenstherapie Berlin-Buch
                    </span>
                    <Logo className="w-auto h-10 sm:h-14 md:h-16" alt="" />
                  </Link>
                </div>
                <div className="-my-2 -mr-2 md:hidden">
                  <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-primary-font hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Menü öffnen</span>
                    <MenuIcon className="w-6 h-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <Popover.Group as="nav" className="hidden space-x-10 md:flex">
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? "text-gray-600" : "text-primary-font",
                            "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          )}
                        >
                          <span>Therapie &amp; Behandlung</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? "text-gray-600" : "text-gray-400",
                              "ml-2 h-5 w-5 group-hover:text-primary-font"
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel
                            static
                            className="absolute z-10 w-screen max-w-md px-2 mt-3 -ml-4 transform sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                          >
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                                {therapyFields.map((item) => (
                                  <Link
                                    key={item.name}
                                    to={item.href}
                                    className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                                  >
                                    <item.icon
                                      className="flex-shrink-0 w-6 h-6 text-indigo-600"
                                      aria-hidden="true"
                                    />
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-gray-600">
                                        {item.name}
                                      </p>
                                      <p className="mt-1 text-sm text-primary-font">
                                        {item.description}
                                      </p>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                              <div className="px-5 py-5 space-y-6 bg-gray-50 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                {callsToAction.map((item) => (
                                  <div key={item.name} className="flow-root">
                                    <a
                                      href={item.href}
                                      className="flex items-center p-3 -m-3 text-base font-medium text-gray-600 rounded-md hover:bg-gray-100"
                                    >
                                      <item.icon
                                        className="flex-shrink-0 w-6 h-6 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <span className="ml-3">{item.name}</span>
                                    </a>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? "text-gray-600" : "text-primary-font",
                            "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          )}
                        >
                          <span>Informationen</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? "text-gray-600" : "text-gray-400",
                              "ml-2 h-5 w-5 group-hover:text-primary-font"
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel
                            static
                            className="absolute z-10 w-screen max-w-md px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
                          >
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                                {resources.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                                  >
                                    <item.icon
                                      className="flex-shrink-0 w-6 h-6 text-indigo-600"
                                      aria-hidden="true"
                                    />
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-gray-600">
                                        {item.name}
                                      </p>
                                      <p className="mt-1 text-sm text-primary-font">
                                        {item.description}
                                      </p>
                                    </div>
                                  </a>
                                ))}
                              </div>
                              {shortInfos.length > 0 && (
                                <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                                  <div>
                                    <h3 className="text-sm font-medium tracking-wide uppercase text-primary-font">
                                      Hinweis
                                    </h3>
                                    <ul className="mt-4 space-y-4">
                                      {shortInfos.map((post) => (
                                        <li key={post.id} className="text-xs ">
                                          <span className="font-medium text-gray-600 hover:text-gray-700">
                                            {post.name}
                                          </span>
                                          {/* <a href={post.href} className="font-medium text-gray-600 hover:text-gray-700">
                                          {post.name}
                                        </a> */}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </Popover.Group>
                <div className="items-center justify-end hidden md:flex md:flex-1 lg:w-0">
                  <Link
                    to="/kontakt"
                    className="text-base font-medium text-primary-font whitespace-nowrap hover:text-gray-600"
                  >
                    Kontakt &amp; Anfahrt
                  </Link>
                </div>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute inset-x-0 top-0 z-20 p-2 transition origin-top-right transform md:hidden"
              >
                <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
                  <div className="px-5 pt-5 pb-6">
                    <div className="flex items-center justify-between">
                      <div>
                        <Logo
                          className="w-auto h-8"
                          alt="Verhaltenstherapie Antje Mützel"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-primary-font hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Menü schließen</span>
                          <XIcon className="w-6 h-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-y-8">
                        {therapyFields.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50"
                          >
                            <item.icon
                              className="flex-shrink-0 w-6 h-6 text-indigo-600"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-base font-medium text-gray-600">
                              {item.name}
                            </span>
                          </Link>
                        ))}
                      </nav>
                    </div>
                  </div>
                  <div className="px-5 py-6 space-y-6">
                    <div className="grid gap-y-4">
                      {resources.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50"
                        >
                          <item.icon
                            className="flex-shrink-0 w-6 h-6 text-indigo-600"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-base font-medium text-gray-600">
                            {item.name}
                          </span>
                        </Link>
                      ))}
                    </div>
                    <div>
                      <Link
                        to="/kontakt"
                        className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-bg hover:bg-indigo-700"
                      >
                        Kontakt
                      </Link>
                      {/* <p className="mt-6 text-base font-medium text-center text-primary-font">
                      Termin vereinbaren?{' '}
                      <button onClick={useGtagReportConversionTel} className="text-primary-font hover:text-indigo-500">
                        Jetzt anrufen
                      </button>
                    </p> */}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
